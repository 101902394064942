import coverageOrProtocolImage from "../../../../src/images/screenshots/coverage_or_protocol.png";
import selectGitHubProvider from "../../../../src/images/screenshots/select_github.png";
import githubOauth from "../../../../src/images/screenshots/github_oauth.png";
import githubInstall from "../../../../src/images/screenshots/install_github.png";
import githubSave from "../../../../src/images/screenshots/install_github_save.png";
import githubSelectProject from "../../../../src/images/screenshots/select_github_project.png";
import * as React from 'react';
export default {
  coverageOrProtocolImage,
  selectGitHubProvider,
  githubOauth,
  githubInstall,
  githubSave,
  githubSelectProject,
  React
};