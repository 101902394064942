import coverageOrProtocolImage from "../../../../src/images/screenshots/coverage_or_protocol.png";
import gitProviderImage from "../../../../src/images/screenshots/git_provider.png";
import selectProjectImageHeartbleed from "../../../../src/images/screenshots/heartbleed/heartbleed-select.png";
import jobPageImageHeartbleed from "../../../../src/images/screenshots/heartbleed/heartbleed-overview.png";
import heartbleedJobPage from "../../../../src/images/screenshots/heartbleed/heartbleed-job.png";
import heartbleedBugPage from "../../../../src/images/screenshots/heartbleed/heartbleed-bug.png";
import heartbleedCorpusPage from "../../../../src/images/screenshots/heartbleed/heartbleed-corpus.png";
import * as React from 'react';
export default {
  coverageOrProtocolImage,
  gitProviderImage,
  selectProjectImageHeartbleed,
  jobPageImageHeartbleed,
  heartbleedJobPage,
  heartbleedBugPage,
  heartbleedCorpusPage,
  React
};