import workflowImage from "../../../../content/doc/100_docs_page/10200_platform_overview/images/fuzzbuzz-workflow.svg";
import githubCheckImg from "../../../../content/doc/100_docs_page/10200_platform_overview/images/github-check.png";
import bugViewImg from "../../../../content/doc/100_docs_page/10200_platform_overview/images/bug-view.png";
import bugJsonViewerImg from "../../../../src/images/screenshots/json_bug.png";
import coverageViewerImg from "../../../../src/images/screenshots/coverage-viewer.png";
import * as React from 'react';
export default {
  workflowImage,
  githubCheckImg,
  bugViewImg,
  bugJsonViewerImg,
  coverageViewerImg,
  React
};