import { createUseStyles as createStyles, useTheme, ThemeProvider, Styles } from "react-jss";

import "../fonts/fonts.css";

export const theme = {
  link: {
    color: "#3B82F6",
  },
  tag: {
    size: "12px",
    caseStudy: {
      color: "#104951",
      background:"#ACE3EE",
    },
    blog: {
      color: "#370464",
      background: "#CA9CF2",
    },
    video: {
      color: "#8D2B0B",
      background: "#FFE5B6",
    },
  },
  button: {
    primary : {
      color: "#FFFFFF",
      background: "#3B82F6",
    },
    secondary: {
      color: "#3B82F6",
      background: "#FFFFFF"
    },
  },
  font: {
    inter: ["Inter", "sans-serif"],
  },
  border: {
    main: {
      color: "#DDE2E9",
    },
  },
  blog : {
    bio: {
      author: {
        size: "14px",
        color: "#3B3B3B",
      },
      details: {
        size: "14px",
        color: "#8592A5",
      },
    },
    paragraph: {
      size: "18px",
      lineHeight: "32",
      color: "#252F3F",
    },
    subheader: {
      size: "24px",
      lineHeight: "32",
      color: "#252F3F",
    },
    margin: {
      subheader_to_parahraph: "10px"
    },
    header: {
      size: "36px",
      lineHeight: "25",
      color: "#18212E",
      
    }
  },
  size: {
      size0: "10px",
      size1: "11px",
      size2: "12px",
      size3: "13px",
      size4: "14px",
      size5: "16px",
      size6: "18px",
      size7: "24px",
      size8: "30px",
  },
  logo: {
    height: "20px",
  },
  nav : {
    height: "72px",
    itemColor: "#18212E",
  }
};

export type CustomTheme = typeof theme;


const createUseStyles = <T extends Record<string, any>>(
	styles:
	  | Styles<any, T, CustomTheme>
	  | ((theme: CustomTheme) => Styles<any, T, undefined>)
  ) => {
	return (props = {} as T) => {
	  const tempTheme = useTheme();
	  const temp = createStyles(styles);
	  return temp({ ...props, theme: tempTheme });
	};
  };

const css = (...styles: (string | boolean | undefined)[]) => {
  return styles.filter((s) => !!s).join(" ");
};

export {css, createUseStyles, useTheme, ThemeProvider };
