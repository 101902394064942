import coverageOrProtocolImage from "../../../../src/images/screenshots/coverage_or_protocol.png";
import gitProviderImage from "../../../../src/images/screenshots/git_provider.png";
import rustSelectProjectImage from "../../../../src/images/screenshots/rust/select_project_rust_tutorial.png";
import jobPageImage from "../../../../src/images/screenshots/job_page.png";
import rustFuzzPageImage from "../../../../src/images/screenshots/rust/rust_job_with_bug.png";
import bugPageImage from "../../../../src/images/screenshots/bug_page.png";
import rustBugReproducerImage from "../../../../src/images/screenshots/rust/rust_bug_reproducer.png";
import rustBugHexViewer from "../../../../src/images/screenshots/rust/rust_bug_hex.png";
import regressionTestingImage from "../../../../src/images/screenshots/regression_testing.png";
import fixedJobImage from "../../../../src/images/screenshots/green_job_details.png";
import * as React from 'react';
export default {
  coverageOrProtocolImage,
  gitProviderImage,
  rustSelectProjectImage,
  jobPageImage,
  rustFuzzPageImage,
  bugPageImage,
  rustBugReproducerImage,
  rustBugHexViewer,
  regressionTestingImage,
  fixedJobImage,
  React
};