// normalize CSS across browsers
import "./src/normalize.css"

// add global
import 'gatsby-plugin-breadcrumb/gatsby-plugin-breadcrumb.css'
import "./src/prismThemes/prism-winter-custom.css";

import { createUseStyles } from "react-jss";

// mdx wrapper components
import React, { useState } from "react";

import { MDXProvider } from "@mdx-js/react";
import Highlight, { defaultProps } from "prism-react-renderer";
import InfoBox from "./src/components/infoBox";
import ImgBox from "./src/components/imgBox";
import Community from "./src/components/community";
import Img from "./src/components/img";
import NoteBox from "./src/components/noteBox";
import copy from "./src/images/copy.svg";
import checkmark from "./src/images/checkmark.svg";

const useStyles = createUseStyles({
  lineStyle: {
    color: "#B5C0CF",
    paddingRight: "20px",
    paddingLeft: "15px",
    userSelect: "none",
    MozUserSelect: "none",
    KhtmlUserSelect: "none",
    WebkitUserSelect: "none",
    OUserSelect: "none",
  },
  codeTitle: {
    fontFamily: "inter",
    fontSize: "12px",
    color: "#B5C0CF",
    paddingBottom: "20px",
  },
  codeContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: "8px",
    background: "#2a2734",
    color: "#9a86fd",
    margin: "0.5em 0",
    padding: "3px 0px 3px 6px",
  },
  copyButton: {
    paddingLeft: "10px",
    paddingRight: "10px",
    cursor: "pointer",
  }
})

const SyntaxHighlighter = props => {
  const className = props?.children?.props?.className;
  const matches = className.match(/language-(?<lang>.*)/);
  const numberLines = props?.children?.props?.numberLines;
  const title = props?.children?.props?.title;
  const classes = useStyles();

  const [copyClicked, setCopyClicked] = useState(false);

  const onClickCopy = (tokens) => {
    setCopyClicked(true);
    setTimeout(() => { setCopyClicked(false) }, 3000);
    let code = "";
    tokens.forEach((line) => {
      line.forEach((token) => {
        code += token.content;
      })
      // if last char is not newline, add newline
      code += code.charAt(code.length - 1) === "\n" ? "" : "\n";
    });

    if (navigator.clipboard) {
      navigator.clipboard.writeText(code.trim());
    } else {
      alert("Copy doesn't work locally: \n" + code.trim());
    }
  };
  return (
    <Highlight
      {...defaultProps}
      code={props?.children?.props?.children?.trim()}
      language={matches?.groups?.lang}
      theme={undefined}
    >
      {({
        className,
        style,
        tokens,
        getLineProps,
        getTokenProps
      }) => (
        <div style={style}>
          <div className={classes.codeContainer}>
            <pre className={className}>
              {
                title && <span className={classes.codeTitle}>{title}</span>
              }
              {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                  {
                    numberLines && <span className={classes.lineStyle}>{i}</span>
                  }
                  {line.map((token, key) => (
                    <span {...getTokenProps({ token, key })} />
                  ))}
                </div>
              ))}
            </pre>
            {
              copyClicked ?
                <img className={classes.copyButton} src={checkmark} alt="copied!"></img>
                :
                <img className={classes.copyButton} src={copy} alt="copy" onClick={() => onClickCopy(tokens)}></img>
            }
          </div>
        </div>
      )}
    </Highlight>
  )
};

const replaceSpaces = (text) => {
  return text.replace(/\s+/g, '-').toLowerCase();
}

const H1 = props => <div><h1 id={replaceSpaces(props.children?.props?.children ?? props.children)} style={{ scrollMarginTop: "80px" }} {...props} /> </div>
const H2 = props => <div><h2 id={replaceSpaces(props.children?.props?.children ?? props.children)} style={{ scrollMarginTop: "80px" }} {...props} /> </div>
const H3 = props => <div><h3 id={replaceSpaces(props.children?.props?.children ?? props.children)} style={{ scrollMarginTop: "80px" }} {...props} /> </div>

const components = {
  h1: H1,
  h2: H2,
  h3: H3,
  pre: SyntaxHighlighter,
  InfoBox: InfoBox,
  ImgBox: ImgBox,
  Img: Img,
  Community: Community,
  NoteBox: NoteBox,
};

export const wrapRootElement = ({ element }) => {
  return (
    <MDXProvider components={{ ...components }}>
      {element}
    </MDXProvider>
  )
}