import img1 from "../../../../content/doc/100_docs_page/30050_protocol_fuzzing/1000_project_setup/images/new_project_1.png";
import img2 from "../../../../content/doc/100_docs_page/30050_protocol_fuzzing/1000_project_setup/images/new_project_2.png";
import img3 from "../../../../content/doc/100_docs_page/30050_protocol_fuzzing/1000_project_setup/images/new_project_3.png";
import img4 from "../../../../content/doc/100_docs_page/30050_protocol_fuzzing/1000_project_setup/images/new_project_4.png";
import img5 from "../../../../content/doc/100_docs_page/30050_protocol_fuzzing/1000_project_setup/images/new_project_5.png";
import img6 from "../../../../content/doc/100_docs_page/30050_protocol_fuzzing/1000_project_setup/images/new_project_6.png";
import img7 from "../../../../content/doc/100_docs_page/30050_protocol_fuzzing/1000_project_setup/images/new_project_7.png";
import img8 from "../../../../content/doc/100_docs_page/30050_protocol_fuzzing/1000_project_setup/images/new_project_8.png";
import img9 from "../../../../content/doc/100_docs_page/30050_protocol_fuzzing/1000_project_setup/images/new_project_9.png";
import img10 from "../../../../content/doc/100_docs_page/30050_protocol_fuzzing/1000_project_setup/images/new_project_10.png";
import img11 from "../../../../content/doc/100_docs_page/30050_protocol_fuzzing/1000_project_setup/images/new_project_11.png";
import * as React from 'react';
export default {
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  React
};