import coverageOrProtocolImage from "../../../../src/images/screenshots/coverage_or_protocol.png";
import gitProviderImage from "../../../../src/images/screenshots/git_provider.png";
import selectProjectImage from "../../../../src/images/screenshots/c/select-project-getting-started-c.png";
import jobPageImage from "../../../../src/images/screenshots/job_page.png";
import fuzzPageImage from "../../../../src/images/screenshots/c/job-bug-page-c.png";
import bugPageImageC from "../../../../src/images/screenshots/c/bug-details-c.png";
import bugReproducerImageC from "../../../../src/images/screenshots/c/bug-reproducer-c.png";
import bugJsonViewer from "../../../../src/images/screenshots/json_bug.png";
import regressionTestingImageC from "../../../../src/images/screenshots/c/regression-tests-c.png";
import fixedJobImageC from "../../../../src/images/screenshots/c/fixed-job-c.png";
import * as React from 'react';
export default {
  coverageOrProtocolImage,
  gitProviderImage,
  selectProjectImage,
  jobPageImage,
  fuzzPageImage,
  bugPageImageC,
  bugReproducerImageC,
  bugJsonViewer,
  regressionTestingImageC,
  fixedJobImageC,
  React
};