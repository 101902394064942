import React from "react";
import { createUseStyles, css } from "../../styling";

const useStyles = createUseStyles({
    noteBox: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginTop: "10px",
        marginBottom: "10px",
        backgroundColor: "#EEF0F4",
        color: "#252F3F",
        border: "1px solid #DDE2E9",
        borderRadius: "4px",
    },
    content: {
        padding: "20px",
        fontSize: "15px",
        lineHeight: "25px",
    },
    warningBox: {
        backgroundColor: "#FEF9E3",
        color: "#78350F",
        border: "1px solid #FDE68A",
    }
})

const NoteBox = ({ content, type = "note" }: { content: React.ReactNode, type?: "warning" | "note" }) => {
    const classes = useStyles();
    return (
        <>
            {type === "warning" ?
                <div className={css(classes.noteBox, classes.warningBox)}>
                    <span className={classes.content}>
                        <b>WARNING</b>
                        <br />
                        {content}
                    </span>
                </div>
                :
                <div className={classes.noteBox}>
                    <span className={classes.content}>
                        <b>NOTE</b>
                        <br />
                        {content}
                    </span>
                </div>
            }
        </>
    )
}

export default NoteBox;


