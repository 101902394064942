import React from "react";
import { Link } from "gatsby";
import { createUseStyles } from "../../styling";

const useStyles = createUseStyles({
    infoContainer: {
        display: "flex",
    },
    infoBox: {
        display: "flex",
        flexDirection: "column",
        padding: "25px",
        border: "1px solid #EEF0F4",
        borderRadius: "8px",
		flex: "1 1 0px",
        //boxShadow: "0px 0px 5px rgba(181, 192, 207, 0.4)",

    "&:hover": {
        boxShadow: "0px 0px 20px rgba(181, 192, 207, 0.22)",
      },
    },
    title: {
        color:"#18212E",
        fontSize: "16px",
        fontFamily: "Inter",
        fontWeight: 500,
        marginBottom: "10px",
    },
    content: {
        fontFamily: "inter",
        fontWeight: 400,
        fontSize: "14px",
        marginBottom: "10px",
        color:  "#3D495B",
        lineHeight: "150%",

    },
    linkButton: {
        color: "#3B82F6",
        marginTop: "10px",
        border: "none",
        fontSize: "14px",
        fontFamily: "inter",
        fontWeight: 400,
        borderRadius: "5px",
        
        textDecoration: "none",
        width:"max-content",
        '& span': {
            color: "#3B82F6",
        }
    }
})

const InfoBox = ({title, button, to, content, img} : {title: string, button: string, to: string, content: string}) => {
    const classes = useStyles();
    return (
        <div className={classes.infoBox}>
            <span className={classes.title}>{title}</span>
            <span className={classes.content}>{content}</span>
            <Link to={to} className={classes.linkButton}>
                <span>{button}</span>
            </Link>
        </div>
    )
}

export default InfoBox;
