import React from "react";
import { Link } from "gatsby";
import { createUseStyles } from "../../styling";

const useStyles = createUseStyles({
    imgBoxContainer: {
        display: "flex",
        paddingBottom: "27px",
        borderBottom: "1px solid #DDE2E9",
        paddingRight: "15px",
        marginBottom: "23px",
        '& > *': {
            width: "50%",
        },
    },
    contentContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingRight: "15px",
    },
    title: {
        color: "#18212E",
        fontFamily: "Inter",
        fontSize: "36px",
        fontWeight: 700,
        marginBottom: "15px",
    },
    content: {
        color: "#3D495B",
        fontFamily: "inter",
        fontSize: "16px",
        lineHeight: "150%",
        marginBottom: "15px",
        paddingRight: "20px",
    },
    infoBox: {
        display: "flex",
        flexDirection: "column",
        maxWidth: "200px",
        padding: "15px",
        borderRadius: "6px",
        boxShadow: "0px 0px 5px rgba(181, 192, 207, 0.4)",
    },
    linkButton: {
        padding: "11px 25px",
        border: "none",
        fontSize: "14px",
        fontFamily: "inter",
        fontWeight: 400,
        borderRadius: "8px",
        backgroundColor: "#3B82F6",
        textDecoration: "none",
        width: "max-content",
        '& span': {
            color: "white",
        }
    },
    '@media screen and (max-width: 750px)': {
        imgBoxContainer: {
            flexDirection: "column-reverse",
            paddingRight: "0px",
            '& > *': {
                width: "auto",
            },
        },
        img: {
            paddingBottom: "15px",
        },
    }
})


const ImgBox = ({ title, button, to, content, img, external }: { title: string, button: string, to: string, content: string, img: string, external: boolean }) => {
    const classes = useStyles();
    return (
        <div className={classes.imgBoxContainer}>
            <div className={classes.contentContainer}>
                <span className={classes.title}>{title}</span>
                <span className={classes.content}>{content}</span>
                {external &&
                    <a href={to} className={classes.linkButton}>
                        <span>{button}</span><span>{" "}→</span>
                    </a>
                }
                {!external &&
                    <Link to={to} className={classes.linkButton}>
                        <span>{button}</span><span>{" "}→</span>
                    </Link>
                }
            </div>
            <img src={require(`../../images/${img}`)} className={classes.img} />
        </div>
    )
}

export default ImgBox;
