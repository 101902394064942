import coverageOrProtocolImage from "../../../../src/images/screenshots/coverage_or_protocol.png";
import gitProviderImage from "../../../../src/images/screenshots/git_provider.png";
import selectProjectImage from "../../../../src/images/screenshots/select_project.png";
import jobPageImage from "../../../../src/images/screenshots/job_page.png";
import fuzzPageImage from "../../../../src/images/screenshots/fuzz_page_stats.png";
import bugPageImage from "../../../../src/images/screenshots/bug_page.png";
import bugReproducerImage from "../../../../src/images/screenshots/bug_reproducer.png";
import bugJsonViewer from "../../../../src/images/screenshots/json_bug.png";
import regressionTestingImage from "../../../../src/images/screenshots/regression_testing.png";
import fixedJobImage from "../../../../src/images/screenshots/green_job_details.png";
import * as React from 'react';
export default {
  coverageOrProtocolImage,
  gitProviderImage,
  selectProjectImage,
  jobPageImage,
  fuzzPageImage,
  bugPageImage,
  bugReproducerImage,
  bugJsonViewer,
  regressionTestingImage,
  fixedJobImage,
  React
};