import React from "react";
import { createUseStyles } from "../../styling";

import twitter from "../../images/community/twitter.svg";
import discord from "../../images/community/discordBox.svg";

const useStyles = createUseStyles({
    community: {
        display: "flex",
        flexDirection: "column",
        marginTop: "32px",
    },
    title: {
        color: "#18212E",
        fontSize: "24px",
        fontFamily: "Inter",
        fontWeight: 600,
        marginBottom: "25px",
        paddingBottom: "7px",
        borderBottom: "1px solid #DDE2E9",
    },
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    socialContainer: {
        display: "flex",
        flexGrow: 1,
        fontWeight: 400,
        textDecoration: "none",
        '& img': {
            marginRight: "15px",
        },
        '& span': {
            fontFamily: "inter",
        }
    },
    socialInfo: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    socialTitle: {
        color: "#3D495B",
        fontSize: "16px",
        marginBottom: "5px",
    },
    socialBody: {
        color: "#8592A5",
        fontSize: "14px",
        fontFamily: "inter",
    },
    '@media screen and (max-width: 750px)': {
        community: {
            marginBottom: "25px",
        },
        socialContainer: {
            marginRight: "20px",
            marginLeft: "10px",
            marginBottom: "28px",
        }

    }
})

const Community = () => {
    const classes = useStyles();
    return (
        <div className={classes.community}>
            <span className={classes.title}>Community</span>
            <div className={classes.container}>
                <a href="https://twitter.com/fuzzbuzz?lang=en" target="_blank" rel="noreferrer" className={classes.socialContainer}>
                    <img src={twitter} alt="twitter" />
                    <div className={classes.socialInfo}>
                        <span className={classes.socialTitle}>Twitter</span>
                        <span className={classes.socialBody}>Stay up to date</span>
                    </div>
                </a>
                <a href="https://discord.fuzzbuzz.io" target="_blank" rel="noreferrer" className={classes.socialContainer}>
                    <img src={discord} alt="discord" />
                    <div className={classes.socialInfo}>
                        <span className={classes.socialTitle}>Discord</span>
                        <span className={classes.socialBody}>Join our community channel</span>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default Community;
