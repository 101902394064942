import coverageOrProtocolImage from "../../../../src/images/screenshots/coverage_or_protocol.png";
import selectGitLabProvider from "../../../../src/images/screenshots/select_gitlab_provider.png";
import gitlabInstructions from "../../../../src/images/screenshots/gitlab_integration_instructions.png";
import gitlabTokenCreation from "../../../../src/images/screenshots/gitlab_token_creation.png";
import gitlabCopyToken from "../../../../src/images/screenshots/gitlab_access_token.png";
import gitlabPasteToken from "../../../../src/images/screenshots/gitlab_enter_access_token.png";
import gitlabSelectProject from "../../../../src/images/screenshots/gitlab_select_project.png";
import * as React from 'react';
export default {
  coverageOrProtocolImage,
  selectGitLabProvider,
  gitlabInstructions,
  gitlabTokenCreation,
  gitlabCopyToken,
  gitlabPasteToken,
  gitlabSelectProject,
  React
};